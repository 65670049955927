<template>
  <div>
    <Menu/>

    <section id="banner" class="my-5">
      <div class="container">
        <div class="row justify-content-center g-0">
          <div class="col-12">
            <div class="banner-section">
              <img v-if="this.$route.name == 'agents'" src="/images/banner-agents.jpg" class="w-100" />
              <img v-if="this.$route.name == 'agent'" src="/images/banner-agents-2.jpg" class="w-100" />
              <img v-if="this.$route.name == 'branches'" src="/images/banner-branches.jpg" class="w-100" />
              <img v-if="this.$route.name == 'branch'" src="/images/banner-branches-2.jpg" class="w-100" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="loading">
      <center><div class="spinner"></div>
      <p><strong>Loading ...</strong></p></center>
    </div>

    <div v-else>
      <section v-if="this.$route.name == 'branch'">
        <div class="container">
          <div class="row">
            <div v-for="(branch, index) in branches['branches']" :key="index" class="col-md-12" v-if="branch[0].id == $route.params.branchId">
              <center><h1><strong>{{ branch[0].branch_name }} Branch</strong></h1></center>
              <center><i class="bi bi-envelope-at-fill"></i> {{ branch[0].email_address }} &nbsp;&nbsp;&nbsp;&nbsp; <i class="bi bi-telephone-plus-fill"></i> {{ branch[0].telephone_number }}</center>
              <center><i class="bi bi-geo-alt-fill"></i> {{ branch[0].postal_address }}</p></center>
              <center><iframe
                width="100%"
                height="450"
                frameborder="0"
                style="border:0"
                :src="'/map.html?lat=' + branch[0].map_x_position + '&lng=' + branch[0].map_y_position + '&zoom=12'"
                allowfullscreen
              ></iframe></center>
            </div>
          </div>
        </div>
        <center><h1>Branch agents</h1></center>
        <div class="row justify-content-center g-3" v-if="!loading">
          <div class="col-md-2 px-3 m-3 lifestyle-block switch-bg-lgrey h-100 bord-radius"
            v-for="(item, index) in filteredAgents"
            v-if="item[0].display_branch == $route.params.branchId"
          >
            <div class="container">
              <div class="row pt-2">
                <div class="col-md-12 justify-content-center">
                  <a :href="'/agents/'+item[0].id"><img :src="item[0].agent_photo" class="img img-fluid" style="border-radius: 10px;" @error="logError" /></a>
                </div>
                <div class="col-md-12 text-end mb-2">
                  <a style="text-decoration: none; font-weight: bold; font-size: 12px; color: ##255699;" :href="'/agents/'+item[0].id"><strong>View profile</strong></a> <i class="bi bi-box-arrow-up-right"></i> 
                </div>
                <div class="col-md-12">
                  <p class="mt-2"><strong>{{ item[0].first_name  }} {{ item[0].last_name  }}</strong><br/><small style="font-size: 12px;">{{ item[0].email_address }}<br/>{{ item[0].cell_number }}</small></p>
                  <div v-for="(branchArray, index) in branches" :key="index" class="pb-3">
                    <div v-for="(branchObject, innerIndex) in branchArray" :key="innerIndex" v-if="branchObject[0].id == item[0].display_branch">
                      <span style="font-size: 14px;">{{ branchObject[0].branch_name ? branchObject[0].branch_name : 'National' }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="this.$route.name == 'agent'">
        <div class="container">
          <h1>Agent Profile</h1>
          <div class="row" v-if="!loading">
            <div class="col-md-12 p-3"
              v-for="(item, index) in filteredAgents"
              v-if="item[0].id == $route.params.agentId"
            >
              <div class="row pt-2">
                <div class="col-md-3 col-8 p-3">
                  <h2><strong>{{ item[0].first_name  }} {{ item[0].last_name  }}</strong></h2><br/>
                  <i class="bi bi-envelope-at-fill"></i> {{ item[0].email_address }} <br/>
                  <i class="bi bi-telephone-forward-fill"></i> {{ item[0].cell_number }}
                  <div v-for="(branchArray, index) in branches" :key="index">
                    <div v-for="(branchObject, innerIndex) in branchArray" :key="innerIndex" v-if="branchObject[0].id == item[0].display_branch">
                      <br/>
                      <h3>{{ branchObject[0].branch_name }}, {{ branchObject[0].province }}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-4 p-3">
                  <center><img :src="item[0].agent_photo" class="img img-fluid" style="border-radius: 10px;" @error="logError" /></center>
                </div>
                <div class="col-md-6 col-12 p-3">
                  <h3>Resumé</h3>
                  <p v-html="decodeResume(item[0].resume)"></p>
                </div>
                
                <div class="row justify-content-center" v-if="!loading">
                  <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4"
                    v-for="(item, index) in listings"
                    v-if="item.payload.listing.data.listing[0].agent == $route.params.agentId"
                  >
                    <div class="banner-section lifestyle-block switch-bg-lgrey p-3 h-100 bord-radius">
                      <div class="portfolio-thumbnail">
                        <a :href="'/property/' + item.payload.listing.data.listing[0].id"><img :src="item.payload.listing.featured_image" class="w-100 force-height"></a>
                        <div class="thumbnail-priceTag">
                          {{ formatCurrency(item.payload.listing.data.listing[0].price) }}
                        </div>
                        <div class="portfolio-type">
                          <div class="typeOne mx-1" v-for="(tag, propertyName) in item.payload.listing.tags" v-if="tag == true">{{ propertyName }}</div>
                        </div>
                      </div>
                      <h4 class="text-start">{{ item.payload.listing.data.listing[0].marketing_heading }}
                        <span>{{ item.payload.listing.data.listing[0].loc_suburb }}, {{ item.payload.listing.data.listing[0].loc_region }}</span>
                      </h4>
                    </div>
                    <a class="social-links switch-bg-dblue mb-0 cta-link" :href="'/property/' + item.payload.listing.data.listing[0].id">FIND OUT MORE</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="this.$route.name == 'branches'">
        <center><h1>Our branches</h1></center>
        <div class="container">
          <div class="row">
            <div v-for="(branch, index) in branches['branches']" :key="index" class="col-md-4 pb-5">
              <div class="row">
                <div class="col-md-11 bg-light p-3" style="border-radius: 30px; min-height: 250px;">
                  <p><strong>{{ branch[0].branch_name }} Branch</strong></p>
                  <p><i class="bi bi-geo-alt-fill"></i> {{ branch[0].province  }} - {{ branch[0].physical_address }}</p>
                  <p><i class="bi bi-telephone-plus-fill"></i> {{ branch[0].telephone_number }} </p>
                  <a style="text-decoration: none; font-weight: bold; font-size: 12px; color: ##255699;" :href="'/branches/'+branch[0].id"><strong>View profile</strong> <i class="bi bi-box-arrow-up-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="this.$route.name == 'agents'">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 text-center mb-3">
              <h1 class="mb-3">OUR TEAM</h1>
              <p>Discover an extraordinary assembly of real estate excellence curated by our team of dedicated professionals. We take pride in presenting an unparalleled selection of properties, showcasing the epitome of luxury living in South Africa.</p>
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-md-6 mb-2">
              <center><label for="globalFilter"><strong>Search</strong> by name or surname</label><br/><br/>
              <input type="text" v-model="globalFilter" class="form-control" id="globalFilter"></center>
            </div>
          </div>
          <div class="row" v-if="!loading">
            <div class="col-6 col-md-2" style="border-radius: 30px;" v-for="(item, index) in filteredAgents" v-if="item.branch_name != ''">
              <div class="container-fluid m-2 bg-light">
                <div class="row pt-2" style="min-height: 450px;">
                  <div class="col-md-12">
                    <a :href="'/agents/'+item[0].id"><img :src="item[0].agent_photo" style="border-radius: 10px;" class="img img-fluid" @error="logError" /></a>
                    <a style="text-decoration: none; font-weight: bold; font-size: 12px; color: ##255699;" :href="'/agents/'+item[0].id"><strong>View profile</strong> <i class="bi bi-box-arrow-up-right"></i></a><br/><br/>
                    <p><strong>{{ item[0].first_name  }} {{ item[0].last_name  }}</strong><br/><small style="font-size: 12px;">{{ item[0].email_address }}<br/>{{ item[0].cell_number }}</small></p>
                    <div v-for="(branchArray, index) in branches" :key="index" class="pb-3">
                      <div v-for="(branchObject, innerIndex) in branchArray" :key="innerIndex" v-if="branchObject[0].id == item[0].display_branch">
                        <i class="bi bi-geo-alt-fill"></i> <span style="font-size: 14px;">{{ branchObject[0].branch_name ? branchObject[0].branch_name : 'National' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

    <Footer/>
  </div>
</template>

<script>
import axios from 'axios'
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'

export default {
  components: {
    Menu, Footer
  },
  data() {
    return {
      listings: [],
      agents: { agents: [] },
      branches: [],
      loading: true,
      selectedType: null,
      selectedLocations: [],
      minPrice: 0,
      maxPrice: 100000000,
      priceRange: [0, 100000000],
      originalListings: [],
      currentPage: 1,
      itemsPerPage: 12,
      locationSearch: '',
      navigationArray: [],
      globalFilter: '',
      loading: true
    }
  },
  mounted() {
    this.fetchListings()
    this.fetchBranches()
    this.fetchAgents()
  },
  watch: {
    
  },
  computed: {
    filteredAgents() {
      return this.agents.agents.filter(agent => {
        const agentData = agent[0] || {};
        const searchString = this.globalFilter.toLowerCase();
        if (
          agentData.hasOwnProperty('first_name') && 
          typeof agentData['first_name'] === 'string' &&
          agentData['first_name'].toLowerCase().includes(searchString)
        ) {
          return true;
        }
        if (
          agentData.hasOwnProperty('last_name') && 
          typeof agentData['last_name'] === 'string' &&
          agentData['last_name'].toLowerCase().includes(searchString)
        ) {
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    decodeResume(htmlString) {
      const element = document.createElement('div');
      element.innerHTML = htmlString;
      return element.textContent.replace(/â€œ|â€/g, '');
    },
    logError: async function (event) {
      event.target.src = "/images/generic.jpg";
    },
    fetchListings: async function () {
      this.loading = true
      try {
        const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.listings = response.data
      } catch (error) {
        console.log(error);
      }
      this.listings = this.listings.filter(listing => {
        return listing.payload && listing.payload.listing && listing.payload.listing.exclusion === '';
      });
      this.navigationArray = this.listings.map((item, index, array) => {
        return {
          currentId: item.payload.listing.data.listing[0].id,
          nextId: (index < array.length - 1) ? array[index + 1].payload.listing.data.listing[0].id : null,
          lastId: (index > 0) ? array[index - 1].payload.listing.data.listing[0].id : null
        };
      });
      localStorage.setItem('setNavigationButtons', JSON.stringify(this.navigationArray))
      this.loading = false
    },
    fetchAgents: async function () {
        await this.fetchListings()

        this.loading = true;
        try {
            const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/agents.php', {
                headers: {
                    'Accept': 'application/json',
                }
            });
            this.agents = response.data;
            for (const agentList of this.agents.agents) {
                for (const agent of agentList) {
                    for (const branchList of this.branches.branches) {
                        for (const branch of branchList) {
                            if(agent.display_branch.includes(',')) {
                              const firstDisplayBranch = agent.display_branch.split(',')[0].trim();
                              agent.display_branch = firstDisplayBranch;
                              
                            }
                            if (branch.id == agent.display_branch) {
                                agent.branch_name = branch.branch_name;
                                agent.branch_province = branch.province;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
        } finally {
            this.loading = false;
        }
    },
    fetchBranches: async function () {
      this.loading = true
      try {
        const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/branches.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.branches = response.data
      } catch (error) {
        console.log(error);
      }
      this.loading = false
    },
  }
}
</script>

<style scoped>
.force-height {
  height: 300px;
  object-fit: cover;
}

.social-links{
  position: unset;
  display: inline;
  float: right;
  margin-right: 20px;
}
/* Scoped keyframes for the spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Spinner class with blue border on top */
.spinner {
  display: inline-block;
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid blue; /* Blue top border */
  border-radius: 50%; /* Circular shape */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Continuous spinning */
}
</style>

